import React, { lazy } from "react";
import { Redirect } from "react-router-dom";
import HomeLayout from "src/layouts/HomeLayout";
import DashboardLayout from "src/layouts/DashboardLayout";
import LoginLayout from "src/layouts/LoginLayout";

export const routes = [
  {
    exact: true,
    path: "/home-2",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home")),
  },
  {
    exact: true,
    path: "/",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/index_2")),
  },
  {
    exact: true,
    path: "/dashboard",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DashboardMain/DashboardIndex")
    ),
  },
  {
    exact: true,
    path: "/vesting",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DashboardMain/Vesting")
    ),
  },
  {
    exact: true,
    path: "/transaction",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/Transaction/TransactionHistory")
    ),
  },
  {
    exact: true,
    path: "/my-wallet",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Wallet/MyWallet")),
  },

  {
    exact: true,
    path: "/kyc",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/KycMain/index")),
  },

  {
    exact: true,
    path: "/buy-token",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/BuyToken/Token")),
  },
  {
    exact: true,
    path: "/edit-profile",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/EditProfile/Profile")
    ),
  },

  {
    exact: true,
    path: "/my-profile",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/EditProfile/Profile")
    ),
  },
  {
    exact: true,
    path: "/login",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/Login")),
  },
  {
    exact: true,
    path: "/reset-password",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/ResetPassword")),
  },
  {
    exact: true,
    path: "/verify-otp",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/VerifyOTP")),
  },
  {
    exact: true,
    path: "/register",

    component: lazy(() => import("src/views/auth/Register")),
  },
  {
    exact: true,
    path: "/forgotpassword",
    layout: LoginLayout,
    component: lazy(() => import("src/views/auth/ForgotPassword")),
  },
  {
    exact: true,
    path: "/contactus",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/Home/ContactUs/ContactUs")),
  },
  {
    exact: true,
    path: "/getintouch",
    layout: HomeLayout,
    component: lazy(() => import("src/views/pages/GetInTouch/GetInTouch")),
  },
  {
    exact: true,
    path: "/termscondition",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/Termscondition")
    ),
  },
  {
    exact: true,
    path: "/privacy-policy",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/StaticContent/PrivacyPolicy")
    ),
  },
  {
    exact: true,
    path: "/notification",
    layout: HomeLayout,
    component: lazy(() =>
      import("src/views/pages/Home/Notification/Notification")
    ),
  },
  {
    exact: true,
    path: "/vesting-purchase",
    layout: DashboardLayout,
    component: lazy(() =>
      import("src/views/pages/Dashboard/DashboardMain/PurchaseCheckout")
    ),
  },
  {
    exact: true,
    path: "/white-paper",
    layout: DashboardLayout,
    component: lazy(() => import("src/views/pages/Dashboard/Whitepaper")),
  },

  {
    exact: true,
    path: "/404",
    component: lazy(() => import("src/views/errors/NotFound")),
  },
  {
    component: () => <Redirect to="/404" />,
  },
];
